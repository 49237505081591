a:hover {
  cursor: pointer;
}
.MuiSvgIcon-root {
  cursor: pointer;
}
.MuiTableCell-root {
  padding: 8px !important;
}
.MuiTableCell-head > span {
  white-space: nowrap;
  display: flex;
  align-items: center;
}
/*
.header-dropdown li i.fa {
  display: none;
}*/
